<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Content from '@/services/Content'
    import Swal from "sweetalert2";
    import { FormWizard, TabContent } from "vue-form-wizard";
    import "vue-form-wizard/dist/vue-form-wizard.min.css";
    import Auth from '@/services/Auth';
    import validationMessages from '@/components/validations'
    import {minLength, required, sameAs, maxLength} from "vuelidate/lib/validators";

    export default {
        components: { 
            FormWizard,
            TabContent,
            Layout, 
            PageHeader,
            validationMessages
        },
        page: {
            title: "Profile",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        data() {
            return {
                csrf_token: localStorage.getItem('csrf_token'),
                message2FA: localStorage.getItem('message2FA'),
                title: "Profile",
                items: [
                    {
                        text: "Profile",
                        active: true,
                        href: "/profile",
                    },
                ],
                new_password: '',
                confirm_password: '',
                current_password: '',
                submitted: false,
                submittedPasswordChange: false,
                tryingToEdit: false,
                show2faWizard: false,
                switch2FA: false,
                switch2FA_DB: false,
                switch2faDisable: true,
                error: '',
                qrCodeImage: '',
                showErrorQRGenerate: false,
                qrCodeImageError: '',
                tryingToGenerateQRCode: false,
                qrCodeGenerateSuccess: false,
                showConfirmErrorAlert: false,
                showConfirmSuccessAlert: false,
                confirmErrorMsg: '',
                confirmSuccessMsg: '',
                secret_code: '',
                tryingToConfirm: false,
                beforeChange: {
                    type: Function
                },
                auth_2fa_card: false,
                showPassChange: true,
                show2FAMessage: false
            };
        },
        validations: {
            current_password:{
                required
            },
            new_password: {
                required,
                valid: function(value) {
                    const containsUppercase = /[A-Z]/.test(value)
                    const containsLowercase = /[a-z]/.test(value)
                    const containsNumber = /[0-9]/.test(value)
                    return containsUppercase && containsLowercase && containsNumber
                },
                minLength: minLength(6),
                maxLength: maxLength(20),
            },
            confirm_password: {
                required,
                sameAsPassword: sameAs('new_password')
            },
        },
        async created() {
            this.auth2faStatus()
        },
        mounted() {
            this.showPassChange = true
            this.show2FAMessage = false
            if(this.message2FA == '2FA_REQUIRED'){
                this.showPassChange = false
                this.show2FAMessage = true
            }
        },
        methods: {

            handleTabChanged(prevIndex, nextIndex){
                if(prevIndex == 1 && nextIndex == 2){
                    this.showConfirmErrorAlert = false
                    this.showConfirmSuccessAlert = false
                    //this.qrCodeGenerateSuccess = false
                    this.showErrorQRGenerate = false
                }
            },

            async changeAdminPassword(){
                this.tryingToEdit = true;
                this.submittedPasswordChange = true;
                this.$v.$touch()

                if (this.$v.$invalid) {
                    this.tryingToEdit = false;
                    return;
                } else {
                    try {
                        await Auth.changeAdminPassword({
                            current_password: this.current_password,
                            new_password: this.new_password,
                            confirm_password: this.confirm_password,
                            csrf_token: this.csrf_token
                        }).then((response) => {
                            this.successmsg(response);
                            this.new_password = '';
                            this.confirm_password = '';
                            this.current_password = '';
                        }).catch(error => {
                            this.error = error.response.data.error ? error.response.data.error : "";
                            this.failedmsg(this.error)
                        }).finally(() => {
                            this.tryingToEdit = false;
                        })
                    } catch (error) {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg(this.error)
                    }
                }
                this.submittedPasswordChange = false;
                this.tryingToEdit = false;
            },

            successmsg() {
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Change has been saved",
                    showConfirmButton: false,
                    timer: 2000,
                });
            },

            failedmsg(msg) {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: msg,
                    showConfirmButton: false,
                    timer: 5000,
                });
            },

            async auth2faStatus(){
                try {
                    Auth.auth2faStatus()
                    .then((response) => {
                        const res = response.data.status ? response.data.status : false;
                        this.switch2faDisable = false
                        if(res=='not_validated' || res=='missing_2fa_record'){
                            this.auth_2fa_card = true
                            this.switch2FA = false
                            this.switch2FA_DB = this.switch2FA
                        } else if(res=='validated') {
                            this.auth_2fa_card = true
                            this.switch2FA = true
                            this.switch2FA_DB = this.switch2FA
                        }                       
                    })
                    .catch(() => {
                        this.auth_2fa_card = false
                        this.switch2FA = false
                        this.switch2FA_DB = this.switch2FA
                    this.switch2faDisable = true
                    })
                }catch(error){
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.switch2FA = false
                    this.switch2FA_DB = this.switch2FA
                    this.switch2faDisable = true
                }
            },

            switch2FAChange(){

                const originalSwitchState = this.switch2FA
                this.switch2FA = !this.switch2FA

                if(this.switch2FA){
                    Swal.fire({
                        title: "Do you want to enable (2FA) ?",
                        icon: "question",
                        showCancelButton: true,
                        confirmButtonColor: "#34c38f",
                        cancelButtonColor: "#f46a6a",
                        confirmButtonText: "Enable",
                    }).then((result) => {
                        if (result.value) {
                            this.show2faWizard = true
                            this.switch2FA = originalSwitchState
                        }else{
                            this.show2faWizard = false
                            this.switch2FA = originalSwitchState
                        }
                    });
                }else{
                    this.show2faWizard = false
                    Swal.fire({
                        title: "Disable Two-Factor Authentication (2FA) ?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#34c38f",
                        cancelButtonColor: "#f46a6a",
                        confirmButtonText: "Disable",
                    }).then((result) => {
                        if (result.value) {
                            Auth.disable2FA()
                                .then(() => {
                                    this.switch2FA = false  
                                    Swal.fire("Two-Factor Authentication (2FA)", "Disable success !", "success");        
                                })
                                .catch((error) => {
                                    this.switch2FA = this.switch2FA_DB;
                                    Swal.fire("Fail!", error.response.data.error, "warning");
                                })
                        }else{
                            this.switch2FA = originalSwitchState
                        }
                    })
                }
            },

            async generateQRCode(){
                this.tryingToGenerateQRCode = true
                this.showErrorQRGenerate = false
                this.qrCodeImageError = ''
                this.qrCodeGenerateSuccess = false
                this.showConfirmErrorAlert = false
                this.showConfirmSuccessAlert = false
                this.secret_code = ''

                Auth.generateQR()
                    .then((response) => {
                        this.qrCodeImage = response.data.qrcode    
                        this.tryingToGenerateQRCode = false 
                        this.qrCodeGenerateSuccess = true           
                    })
                    .catch((error) => {
                        this.tryingToGenerateQRCode = false       
                        this.showErrorQRGenerate = true
                        this.qrCodeImageError = error.response.data.error
                    })
            },

            async confirm2FA(){
                this.submitted = true;
                this.showConfirmErrorAlert = false
                this.showConfirmSuccessAlert = false
                this.tryingToConfirm = true
                this.qrCodeGenerateSuccess = false
                this.showErrorQRGenerate = false

                Auth.confirm2FA({
                    secret_code: this.secret_code,
                    csrf_token: this.csrf_token
                })
                .then(() => {
                    this.showConfirmSuccessAlert = true
                    this.confirmSuccessMsg = 'Logout/Login for the change to take effect'
                    this.tryingToConfirm = false
                    this.secret_code = ''
                    this.show2faWizard = false
                    this.switch2FA = true
                    Swal.fire("Confirmation Success",  this.confirmSuccessMsg, "success"); 
                })
                .catch((error) => {
                    this.showConfirmErrorAlert = true
                    this.confirmErrorMsg = error.response.data.error
                    this.tryingToConfirm = false
                    this.secret_code = ''
                })
            }


        },
        middleware: "authentication",
    };
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="row" v-show="showPassChange">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h2 class="card-title mb-3 text-center">Password Change</h2>

                        <form @submit.prevent="changeAdminPassword">

                            <div class="row">
                                <div class="col-md-4 ">
                                    <b-form-group label="Current Password" label-for="formrow-current_password-input" class="mb-3">
                                        <b-form-input
                                                v-model.trim="current_password"
                                                type="password"
                                                id="current_password"
                                                aria-describedby="current_password-feedback"
                                                :class="{
                                          'is-invalid': submittedPasswordChange && $v.current_password.$error,
                                        }"
                                        >
                                        </b-form-input>
                                        <validationMessages v-if="submittedPasswordChange" :fieldName="'Current Password'" :validationName="$v.current_password"></validationMessages>
                                    </b-form-group>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-4 ">
                                    <b-form-group label="New Password" label-for="formrow-new_password-input" class="mb-3">
                                        <b-form-input
                                                v-model.trim="new_password"
                                                type="password"
                                                id="new_password"
                                                aria-describedby="new_password-feedback"
                                                :class="{
                                          'is-invalid': submittedPasswordChange && $v.new_password.$error,
                                        }"
                                        >
                                        </b-form-input>
                                        <validationMessages v-if="submittedPasswordChange" :fieldName="'New Password'" :validationName="$v.new_password"></validationMessages>
                                    </b-form-group>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-4 ">
                                    <b-form-group label="Confirm Password" label-for="formrow-confirm_password-input" class="mb-3">
                                        <b-form-input
                                                v-model.trim="confirm_password"
                                                type="password"
                                                id="confirm_password"
                                                aria-describedby="confirm_password-feedback"
                                                :class="{
                                          'is-invalid': submittedPasswordChange && $v.confirm_password.$error,
                                        }"
                                        >
                                        </b-form-input>
                                        <validationMessages v-if="submittedPasswordChange" :fieldName="'Confirm Password'" :validationName="$v.confirm_password"></validationMessages>
                                    </b-form-group>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-4 ">
                                    <b-button variant="primary" @click="changeAdminPassword" :disabled="tryingToEdit">Change</b-button>
                                </div>
                            </div>
                            <input type="hidden" name="csrf_token" v-model="csrf_token">
                        </form>
                    </div>   
                </div>  
            </div>  
        </div> 

        <b-alert variant="warning" :show="show2FAMessage" dismissible>
            Configure Two-factor authentication (2FA) before continue using Admin Panel !
        </b-alert>

        <div class="row" v-show="auth_2fa_card">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">


                        <h2 class="card-title mb-3 text-center">Two FactorAauthentication (2FA)</h2>
                        <div class="form-check form-switch form-switch-lg mb-5" >
                            <label class="form-check-label" for="enable2FA">Enable 2FA</label>
                            <input type="checkbox" class="form-check-input" id="enable2FA" :checked="switch2FA" @click="switch2FAChange()" :disabled="switch2faDisable" />
                        </div>
                        
                        <form-wizard color="#5b73e8" v-show="show2faWizard" @on-change="handleTabChanged">
                            <tab-content icon="mdi mdi-book-open-page-variant-outline">
                                <div class="row">
                                    <div class="col-12">
                                        <h5 class="mb-4">Protect your account with 2 Step Verification: </h5>

                                        <ol>
                                            <li class="mb-2">
                                                Install Google Authenticator app on your mobile device:
                                                <a class="ms-4 me-4" href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US&pli=1" target="_blank">Android</a>
                                                <a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank">IOS</a>
                                            </li>
                                            <li class="mb-2">
                                                Generate and Scan QR Code with Google Authenticator App
                                            </li>
                                            <li class="mb-2">
                                                Confirm App code generated from Google Authenticator App
                                            </li>
                                        </ol>
                                    </div>
                                <!-- end col -->
                                </div>
                                <!-- end row -->
                            </tab-content>
                            <tab-content icon="mdi mdi-qrcode-scan" class="mb-5">
                                <div class="row">
                                    <div class="col-12">
                                        <h5  class="mb-5 d-inline me-3">Generate and Scan QR Code:</h5>
                                        <a href="javascript:void(0);" class="btn btn-success waves-effect waves-light" @click="generateQRCode()">
                                            <i class="mdi mdi-qrcode-scan me-2"></i>
                                            Generate
                                            <b-spinner v-show="tryingToGenerateQRCode" small></b-spinner>
                                        </a>
                                        <div>
                                            <h6 class="mb-5 mt-3">* If you previously used Google Authenticator App for this Admin and still installed, continue to next step, otherwise generate new Code !</h6>
                                        </div>
                                        <div v-show="qrCodeGenerateSuccess" v-html="qrCodeImage"></div>
                                        <b-alert class="mt-5 mb-5" variant="danger" v-model="showErrorQRGenerate">{{ qrCodeImageError }}</b-alert>
                                        <b-alert  variant="success" v-model="qrCodeGenerateSuccess">Scan QR Code with your Application and go to NEXT Step</b-alert>
                                    </div>
                                <!-- end col -->
                                </div>
                                <!-- end row -->
                            </tab-content>
                            <tab-content icon="mdi mdi-checkbox-marked-circle-outline">
                                <div class="row">
                                    <div class="col-12">
                                        <h5 class="mb-5">Enter Secret Code from your Application for confirmation:</h5>
                                    </div>
                                    <div class="mt-3 mb-5">
                                        <form class="row row-cols-lg-auto gx-3 gy-2 align-items-center" @submit.prevent="confirm2FA">
                                            <div class="col-12">
                                                <input type="text" class="form-control" id="input_secret_code" v-model="secret_code" placeholder="Enter Secret Code" autocomplete="off"/>
                                            </div>
                                            <div class="col-12">
                                                <button type="submit" class="btn btn-success"  :disabled="tryingToConfirm">
                                                    <b-spinner v-show="tryingToConfirm" small ></b-spinner>
                                                    Confirm
                                                </button>
                                            </div>
                                            <input type="hidden" name="csrf_token" v-model="csrf_token">
                                        </form>
                                    </div>
                                    <b-alert class="mt-2 mb-5" variant="danger"  v-model="showConfirmErrorAlert" >{{ confirmErrorMsg }}</b-alert>
                                    <b-alert class="mt-2 mb-5" variant="success" v-model="showConfirmSuccessAlert">{{ confirmSuccessMsg }}</b-alert>
                                <!-- end col -->
                                </div>
                                <!-- end row -->
                            </tab-content>
                        </form-wizard>
                    </div>
                    <!-- end card-body -->
                </div>
            </div>
        </div>

    </Layout>
</template>